<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">
      <!-- Page sections -->
      <PropojeniIntro />
      <PropojeniInfo />
      <Cta />
    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import PropojeniIntro from './../partials/PropojeniIntro.vue'
import PropojeniInfo from './../partials/PropojeniInfo.vue'
import Cta from './../partials/Cta.vue'
import Footer from './../partials/Footer.vue'

export default {
  name: 'Propojeni',
  title: 'Együttműködés',
  components: {
    Header,
    PropojeniIntro,
    PropojeniInfo,
    Cta,
    Footer,
  },
}
</script>
