<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
        <div class="max-w-sm mx-auto md:max-w-none">

          <!-- Articles container -->
          <div class="grid pb-12 gap-12 md:grid-cols-3 md:col-gap-6 md:row-gap-8 items-start">

            <!-- 1st article -->
            <article class="flex flex-col h-full" data-aos="zoom-y-out">
              <header>
                  <figure class="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded block mb-6">
                    <img class="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" :src="require('@/images/tc/propojeni/Tekla.webp')" width="352" height="198" alt="News 01" />
                  </figure>
                <h3 class="text-xl font-bold leading-snug tracking-tight mb-2">Tekla Structures</h3>
              </header>
              <p class="text-gray-600 flex-grow">A Trimble Connect hatékony eszközöket biztosít a Tekla Structures felhasználói számára a hatékonyságuk növeléséhez. A natív formátumú Tekla modellek közvetlen exportálása/importálása, feladatok kölcsönös szinkronizálása vagy munkafolyamatokkal támogatott munkavégzés. Dolgozzon modern környezetben, ahol akár távoli eléréssel is lehetősége van a Trimble Connectben tárolt modellek visszavetítésére a Tekla program alá.</p>
            </article>

            <!-- 2nd article -->
            <article class="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="150">
              <header>
                  <figure class="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded block mb-6">
                    <img class="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" :src="require('@/images/tc/propojeni/Revit.webp')" width="352" height="198" alt="News 01" />
                  </figure>
                <h3 class="text-xl font-bold leading-snug tracking-tight mb-2">Autodesk Revit</h3>
              </header>
              <p class="text-gray-600 flex-grow">A Trimble Connect egy nagyszerű eszköz a Revit felhasználók számára. Automatikusan exportálhat vagy importálhat modelleket közvetlenül a Revitbe és a Revitből. Használja Trimble Connect alkalmazásunkat a Revithez, és takarítson meg időt!</p>
            </article>

            <!-- 1st article -->
            <article class="flex flex-col h-full" data-aos="zoom-y-out">
              <header>
                  <figure class="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded block mb-6">
                    <img class="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" :src="require('@/images/tc/propojeni/SketchUp.webp')" width="352" height="198" alt="News 01" />
                  </figure>
                <h3 class="text-xl font-bold leading-snug tracking-tight mb-2">SketchUp</h3>
              </header>
              <p class="text-gray-600 flex-grow">Használja ki a Trimble Connect és a SketchUp közötti kapcsolatot. Automatikusan közzéteszi a modelleket a felhőben, és hozzáférést biztosít csapata számára a szükséges információkhoz.</p>
            </article>

            <!-- 3rd article -->
            <article class="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="300">
              <header>
                  <figure class="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded block mb-6">
                    <img class="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" :src="require('@/images/tc/propojeni/Prolog.webp')" width="352" height="198" alt="News 01" />
                  </figure>
                <h3 class="text-xl font-bold leading-snug tracking-tight mb-2">OpenBIM</h3>
              </header>
              <p class="text-gray-600 flex-grow">A fejlett 3D nézegetőben különböző forrásból – OPEN BIM elveket követve pl.: IFC, Tekla, Revit, DWG, DXF, DGN, SKP, IGS, STEP, LandXML és TRimBim - származó modelleket összekapcsolhatjuk, nézetekbe rendezhetjük és együtt tovább használhatjuk.</p>
            </article>

            <!-- 2nd article -->
            <article class="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="150">
              <header>
                  <figure class="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded block mb-6">
                    <img class="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" :src="require('@/images/tc/propojeni/FieldLayout.webp')" width="352" height="198" alt="News 01" />
                  </figure>
                <h3 class="text-xl font-bold leading-snug tracking-tight mb-2">3D pontfelhők</h3>
              </header>
              <p class="text-gray-600 flex-grow">A modellek egyesített megtekintése mellett a E57, LAS, LAZ, Potree, TDX, TZF, XYZ formátumú 3D pontfelhő állományokat is hatékonyan megjeleníti.</p>
            </article>

            <!-- 3rd article -->
            <!-- <article class="flex flex-col h-full" data-aos="zoom-y-out" data-aos-delay="300">
              <header>
                  <figure class="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded block mb-6">
                    <img class="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" :src="require('@/images/tc/propojeni/Vico.webp')" width="352" height="198" alt="News 01" />
                  </figure>
                <h3 class="text-xl font-bold leading-snug tracking-tight mb-2">Vico Office</h3>
              </header>
              <p class="text-gray-600 flex-grow">Moderně a efektivně generujte 4D rozvrhy a nacenění ve VICO office z 3D modelů uložených v Trimble Connectu. Zjednodušte si práci!</p>
            </article> -->

          </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutIntro',
}
</script>